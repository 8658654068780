<template>
    <div class="mcontainer">
        <div class="d-flex justify-content-between align-items-center mb-4">
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <router-link to="/app/group">Groups</router-link>
                        </li>
                        <li class="active">
                            <a href="#">Laporan </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        <div>
            <b-row v-if="data.length > 0" class="flex flex-wrap">
                <b-table
                    :responsive="true"
                    :fields="fields"
                    :items="data"
                >
                    <template #cell(index)="row">
                        {{ (row.index + 1) }}
                    </template>
                    <template #cell(user)="row">
                        <div>
                            <p>{{ row.item.user.fullname }}</p>
                        </div>
                    </template>
                    <template #cell(group)="row">
                        <div>
                            <p>{{ row.item.group.title }}</p>
                        </div>
                    </template>
                    <template #cell(created_at)="row">
                        <div>
                            <p>{{ formatDateTime(row.item.created_at) }}</p>
                        </div>
                    </template>
                </b-table>
            </b-row>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'
    export default {
        computed: mapState('reports', ['loading', 'data', 'fields']),
        methods: {
            ...mapActions('reports', ['fetch'])
        },
        created () {
            this.fetch()
        }
    }
</script>
